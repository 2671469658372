<template>
  <main>
    <section class="Banner">
      <v-container>
        <div class="Banner__Content">
      <div class="Banner__Content__Img">
        <v-img
              class="bg-camara"
              contain
              height="300px"
              src="../../assets/Camara.png"
            ></v-img>
      </div>
      <div class="Banner__Content__Info">
        <h1 class="Banner__Content__Info__Title">SOSCAM</h1>
        <h2 class="Banner__Content__Info__Subtitle">Tu Solución Integral de Seguridad</h2>
        <div class="hr"></div>

      </div>
        </div>
      </v-container>
    </section>
    <v-container>
        <h2 class="mt-2">Elige tu Cámara</h2>
        <v-divider class="mt-2"></v-divider>
      <v-row class="mt-2 mb-5" v-if="!loading">
        <v-col class="d-flex justify-center" cols="12" md="4" v-for="product in catalog_products" :key="product.product_id">
          <v-card class="CardCustom" elevation="0">
            <v-btn class="d-flex ml-auto d-flex align-center" rounded outlined @click="openVideo(product.product_id)" style="z-index: 10;">Video <v-icon>mdi-video</v-icon></v-btn>
            <v-img
            v-if="product.product_id == 8"
              contain
              height="200px"
              src="../../assets/Camara.png"
            >
              <v-container fill-height fluid>
                <v-layout fill-height>
                  <v-flex xs12 align-end flexbox>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-img>
            <v-img
              v-if="product.product_id == 9"
              contain
              height="200px"
              src="../../assets/soscamexta.png"
            >
              <v-container fill-height fluid>
                <v-layout fill-height>
                  <v-flex xs12 align-end flexbox>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-img>
            <v-card-title style="justify-content:center;margin-bottom: 0; padding-bottom: 0;">
              <div class="mx-5">
                <p class="CardCustom__Title">{{productName(product.product_id)}}</p>
                <p class="CardCustom__Price" style="text-align: center;">${{product.price | formatNumber}}</p>
              </div>
            </v-card-title>
            <v-card-actions style="display:flex; flex-direction: column; gap: 8px;" class="pt-2">
              <v-btn
                style="z-index: 10;  width: 150px;"
                large
                rounded
                depressed
                class="mx-auto"
                @click="$router.push(productPath(product.product_id))"
                >Ver Detalle</v-btn>
                
              <v-btn
                @click="addToCart(product.product_id)"
                style="z-index: 10;  width: 150px;"
                color="#D32527"
                large
                rounded
                depressed
                class="mx-auto"
                ><span style="color: #FFFF;">Comprar</span></v-btn>
            </v-card-actions>
        </v-card>
        </v-col>
      </v-row>
      <p v-else>Cargando...</p>
    </v-container>

 <v-dialog v-if="dialog" v-model="dialog" width="800">
            <div style="position: relative; background-color: black">
              <v-icon
                @click="dialog = false"
                class="video-icon"
                large
                color="white darken-2"
                style="position: absolute; right: 10px; z-index: 123;"
              >
                mdi-close
              </v-icon>
              <video autoplay style="width: 100%; height: 400px" controls>
                <source :src="videoUrl" type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          </v-dialog>
  </main>
</template>

<script>
import { getCartData } from '../../Helpers/api/general'

export default {
  data() {
    return {
      dialog: false,
      videoUrl: '',
      loading: true,
      products_request: [
        {product_id: 8, qty: 1, },
        {product_id: 9, qty: 1, }
      ],
      catalog_products: [],
      discount: null,
    };
  },
  methods: {
    productName(id) {
      const names = {
        9: 'SOSCAM EXT-A',
        8: 'SOSCAM'
      }
      return names[id]
    },
    productPath(id) {
      const paths = {
        9: '/SOSCAM-EXT-A',
        8: '/SOSCAM'
      }
      return paths[id]
    },
    addToCart(product_id) {
        const productIndex = this.$store.state.products.findIndex(product => product.product_id === product_id);
            if (productIndex  === -1) {
                this.$store.commit('setCart', [...this.$store.state.products, {product_id, qty: 1}])
            } else {
                const arr = this.$store.state.products
                arr[productIndex].qty += 1;
                this.$store.commit('setCart', arr)
            }
            setTimeout(() => {
                //loader
                this.$router.push({ name: 'SOSCamSingle' })
            }, .5);
        },
        getProductPrice() {
          const products = this.products_request.map(product => ({...product,id: product.product_id}));
          const data =  {products, coupon_code: this.$store.state.discountCode}
          this.discount = true
          if (!this.$store.state.discountCode) {
            delete data.coupon_code
            this.discount = false
          }

          getCartData(data).then(res => {
              this.catalog_products = res.cart_data.products
              this.loading = false
          }).catch(function (error) {
    /*         this.loadingbtn = false */
            console.log(error);
            });
    },
    openVideo(id) {
      if (id == 8) {
        this.videoUrl = require('../../assets/videos/Cámara Interior.mp4')
      } else if (id == 9) {
        this.videoUrl = require('../../assets/videos/Cámara Exterior.mp4')
      }
      this.dialog = true;
    }
    },
    mounted () {
      this.getProductPrice();
    },
    filters: {
        formatNumber: function(value) {
          return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        }
    }
};
</script>

<style lang="scss" scoped>
.Banner {
  background-image: url('../../assets/banner.png');
  background-position-x: -1px;
  padding-top: 30px;
    &__Content {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
      &__Info {
      color: #FFFF;
      width: 50%; 
      &__Title {
        font-size: 50px;
        margin-bottom: 0;
        margin-top: 0;
      }
      &__Subtitle {
        margin-top: 0;
        padding-top: 0;
      }
    }
    &__Img {
      width: 350px;
    }
    }
  }

  @media (max-width: 685px) {
    .Banner {
      width: 100%;
    &__Content {
      flex-direction: column;
      &__Info {
        width: 100%;
        text-align: center;
      &__Title {

      }
      &__Subtitle {

      }
    }
    &__Img {
      max-width: 200px;
    }
    }
  }
  }

.CardCustom {
    border: 1px solid #dbd7d7;
    background-color: #f3f0f0;
    border-radius: 15px;
    padding: 20px;  
    transition: all 0.7s ease;
    max-width: 360px;
    &:hover {
      border: 1px solid #D32527;
    }
    &__Title {
      font-size: 18px;
      color: #111111;
      margin-bottom: 0;
      text-align: center;
    }
    &__Price {
      margin-bottom: 0;
      font-size: 24px;
      color: #D32527;
    }
}

@keyframes border {
  to {
    border: 1px solid #D32527;
  }
}
.hr {
    border: 3px solid #BFC0C0; width: 194px;
}
  @media (max-width: 685px) {
.hr {
    display: none;
}
  }

  .bg-camara {
  background-image: url("../../assets/imgs/circle-bg-2.png");
  background-size: cover;
}
</style>
